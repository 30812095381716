.dashboardContainer {
  .card {
    display: flex;
    padding: 15px;
    box-shadow: 0px 0px 5px grey;
    cursor: pointer;
    border-radius: 5px;
    align-items: center;
    gap: 0.5em;
    justify-content: space-between;
    min-width: 300px;
    background: linear-gradient(195deg, #4CB8DB,#4CB8DB) !important;
    color: white;

    div {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 0.5em;

      p {
        margin: 0px;
        font-size: 30px !important;
        font-weight: bold !important;
      }

      span {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}
