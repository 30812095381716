body {
    font-family: 'Alata', sans-serif;
    margin: 0;
    /*overflow: hidden;*/
}

.App {
    width: 100vw;
    height: 100vh;
    background: #FBFBFB;
    margin: 0;
}

.login {
    background: #FBFBFB;
    display: flex;
    height: 100vh;
}

.loginImage {
    height: 100vh;
    width: calc(100vw - 500px);
    background-image: url("./assets/heromainbgx2.webp");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

.loginImage > img {
    width: 40%;
}

.loginForm {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #64656A;
    font-size: 25px;
}

.loginLogo {
    width: 150px
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 2rem;
    height: 50px;
    background: linear-gradient(195deg, #4CB8DB, #19415f, #4CB8DB) !important;
}

.navbarLogo {
    transform: scale(0.3);
    padding: 2px;
}

.sidebar {
    width: 100%;
    height: 100%;
    color: #00224D;
    font-size: 1.2rem;
}

.mainComponent {
    height: 100%;
}

.mainBody {
    padding: 2rem;
}

.sidebarRow {
    color: white;
    padding: 10px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    user-select: none
}

.sidebarRow > svg {
    fill: white
}

.sidebarRow > span {
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.sidebarRow:hover {
    background: linear-gradient(195deg, #4CB8DB, #4CB8DB) !important;
    padding: 10px 5px;
    border-radius: 5px
}


.suspendedUser {
    background: #ff726b;
}


.dashboard-image {
    width: 50%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
    margin: 10px 0;
    opacity: 0;
    transform: scale(0.8);
    animation: fadeInScale 5s forwards;
}

@keyframes fadeInScale {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.checkbox-green  .ant-checkbox-inner {
    background-color: limegreen !important; /* Set background color */
    border-color: limegreen !important;     /* Set border color to match background */
}

.checkbox-green  .ant-checkbox-inner::after {
    border-color: black !important; /* Set the checkmark color to black */
}

.checkbox-green .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: limegreen !important; /* Apply background to disabled state */
    border-color: limegreen !important;     /* Border in disabled state */
}

.checkbox-green .ant-checkbox-checked .ant-checkbox-inner {
    background-color: limegreen !important; /* Ensure checked state has green background */
    border-color: limegreen !important;
}

.checkbox-green .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: black !important; /* Ensure checkmark is black when checked */
}

.checkbox-yellow .ant-checkbox-inner {
    background-color: gold !important; /* Set background color */
    border-color: gold !important;     /* Set border color to match background */
}

.checkbox-yellow .ant-checkbox-inner::after {
    border-color: black !important; /* Set the checkmark color to black */
}

.checkbox-yellow.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: gold !important; /* Apply background to disabled state */
    border-color: gold !important;     /* Border in disabled state */
}

.checkbox-yellow.ant-checkbox-checked .ant-checkbox-inner {
    background-color: gold !important; /* Ensure checked state has yellow background */
    border-color: gold !important;
}

.checkbox-yellow.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: black !important; /* Ensure checkmark is black when checked */
}

.checkbox-red .ant-checkbox-inner {
    background-color: red !important; /* Set background color */
    border-color: red !important;     /* Set border color to match background */
}

.checkbox-red .ant-checkbox-inner::after {
    border-color: black !important; /* Set the checkmark color to black */
}

.checkbox-red.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: red !important; /* Apply background to disabled state */
    border-color: red !important;     /* Border in disabled state */
}

.checkbox-red.ant-checkbox-checked .ant-checkbox-inner {
    background-color: red !important; /* Ensure checked state has red background */
    border-color: red !important;
}

.checkbox-red.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: black !important; /* Ensure checkmark is black when checked */
}
